import { useContext, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import AgoraRTM from 'agora-rtm-sdk';
import { v4 as uuidv4 } from 'uuid';
import {
  RootContext,
  MembersDispatchContext,
  AgoraErrCode,
  getAgoraErrorMessage,
} from '../../index';

export const useAgoraChat = ({ agoraUser, onMemberJoined = () => {}, onMemberLeft = () => {} }) => {
  const context = useContext(RootContext);
  const dispatchMembers = useContext(MembersDispatchContext);
  const client = useRef(null);
  const channel = useRef(null);
  const [joined, setJoined] = useState(false);
  const systemCallPrefix = '/systemcall';

  const getUuid = () => {
    const initialUuid = window.localStorage.getItem('uuid') || uuidv4();
    if (!window.localStorage.getItem('uuid')) {
      window.localStorage.setItem('uuid', uuid);
    }
    return initialUuid;
  };
  const uuid = getUuid();

  const checkState = async () => {
    const body = await context.fetch(`${agoraUser.current.api_location.token}?uuid=${uuid}`);
    if (body.result.state !== context.state) {
      location.reload();
    }
    return body.result;
  };

  const join = async () => {
    client.current = AgoraRTM.createInstance(agoraUser.current.app_id);
    try {
      await client.current.login({ uid: agoraUser.current.uid, token: agoraUser.current.chat_token });
    } catch (e) {
      throw getAgoraErrorMessage(AgoraErrCode.LoginChat, e);
    }

    channel.current = client.current.createChannel(agoraUser.current.channel_name);

    setEventHandlers();

    try {
      await channel.current.join();
    } catch (e) {
      throw getAgoraErrorMessage(AgoraErrCode.JoinChatChannel, e);
    }
    setJoined(true);
    return channel.current;
  };

  /**
   * 自分を含む
   * @return {Array<string>} uid
   */
  const getMemberUids = async () => {
    return await channel.current?.getMembers() || [];
  };

  const onReceiveMessage = (callback) => {
    // メッセージ受信
    channel.current.on('ChannelMessage', (message, senderUid) => {
      if (!message.text.startsWith(systemCallPrefix)) {
        callback(message.text, senderUid);
      }
    });
  };

  const removeOnReceiveMessageListener = (callback) => {
    channel.current?.removeListener('ChannelMessage', callback);
  };

  const setEventHandlers = () => {
    channel.current.on('MemberJoined', (uid) => {
      onMemberJoined(uid);
    });
    channel.current.on('MemberLeft', (uid) => {
      dispatchMembers({
        type: 'member_left',
        uid: uid,
      });
      onMemberLeft(uid);
    });
  };

  const sendMessage = async (text) => {
    if (!text) return;

    await channel.current?.sendMessage({ text: text });
  };

  const systemCall = async (type, uid = null) => {
    const text = `${systemCallPrefix} type:${type} uid:${uid}`;
    await sendMessage(text);
  };

  const onReceiveSystemCall = (callback) => {
    channel.current.on('ChannelMessage', (message, senderUid) => {
      if (message.text.startsWith(systemCallPrefix)) {
        const match = /\stype:(?<type>\S+).*\suid:(?<uid>\S+)/.exec(message.text);
        callback(match.groups.type, match.groups.uid);
      }
    });
  };

  const renewToken = async () => {
    const body = await context.fetch(`${agoraUser.current.api_location.token}?uuid=${uuid}`);
    await client.current.renewToken(body.result.chat_token);
    return body.result.video_id;
  };

  const leave = async () => {
    try {
      await channel.current?.leave();
    } catch (e) {
      throw getAgoraErrorMessage(AgoraErrCode.LeaveChat, e);
    }
    try {
      await client.current?.logout();
    } catch (e) {
      throw getAgoraErrorMessage(AgoraErrCode.LogoutChat, e);
    }
  };

  useEffect(() => {
    return () => leave();
  }, []);

  return {
    checkState,
    joined,
    join,
    getMemberUids,
    onReceiveMessage,
    removeOnReceiveMessageListener,
    sendMessage,
    systemCall,
    onReceiveSystemCall,
    renewToken,
    leave,
  };
};

useAgoraChat.propTypes = {
  agoraUser: PropTypes.object.isRequired,
  onMemberJoined: PropTypes.func,
  onMemberLeft: PropTypes.func,
};
