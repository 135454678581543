import * as React from 'react';
import * as PropTypes from 'prop-types';
import { ExaminationTimes } from '../ExaminationTimes';
import { RootContext } from '../../../../index';

export default class InvoiceLayout extends React.Component {
  static contextType = RootContext;

  render () {
    const patient = this.context.treatment.patient;
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

    return (
      <>
        <div className="columns has-background-white is-centered" style={{ marginRight: scrollbarWidth - 7.5 }}>{/* -7.5 は既存の余白設定 */}
          <div className="column is-half pb-xl">
            <h1 className="fz32 has-text-weight-bold has-text-centered my-xl">
              {patient.name}さんへの請求{patient.stripe_customer_id ? '内容' : '確認'}
            </h1>
            {this.props.children}
            <div className="has-text-centered">
              <a className="" href="/treatments?history=1">
                <span>患者一覧に戻る</span>
              </a>
            </div>
          </div>
        </div>
        <div className="columns mt-lg" style={{ marginRight: scrollbarWidth - 7.5 }}>{/* -7.5 は既存の余白設定 */}
          <div className="column p0">
            <ExaminationTimes />
          </div>
        </div>
      </>
    );
  }
}

InvoiceLayout.propTypes = {
  children: PropTypes.object, // inline表示するDOM要素
};
