import { useState } from 'react';

const STATUSES = {
  CLOSE: 0,
  MINIMUN: 1, // 最小化
  OPEN: 2,
};

export const useChatDisplayStatus = () => {
  const [status, setStatus] = useState(STATUSES.CLOSE); // チャットモーダルの状態
  const [hasUnread, setHasUnread] = useState(false); // チャットの未読

  const isClose = status === STATUSES.CLOSE;
  const isMinimum = status === STATUSES.MINIMUN;
  const isOpen = status === STATUSES.OPEN;

  const unreadBadge = !isOpen && hasUnread;

  const open = () => {
    setHasUnread(false);
    setStatus(STATUSES.OPEN);
  };

  const close = () => {
    // Open → Close の場合は未読バッチ不要
    if (isOpen) setHasUnread(false);
    setStatus(STATUSES.CLOSE);
  };

  const minimum = () => {
    // Open からしか来ないので未読バッチ不要
    setHasUnread(false);
    setStatus(STATUSES.MINIMUN);
  };

  const toggletMinimum = () => {
    isOpen ? minimum() : open();
  };

  const onReceive = () => {
    // callbackで呼ばれるので、最新のstateが取得できない
    setHasUnread(true);
  };

  return {
    isClose,
    isMinimum,
    isOpen,
    unreadBadge,
    open,
    close,
    toggletMinimum,
    onReceive,
  };
};
