import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
export const MembersContext = createContext(null);
export const MembersDispatchContext = createContext(null);

export const MembersProvider = ({ children }) => {
  const [members, dispatch] = useReducer(membersReducer, []);

  return (
    <MembersContext.Provider value={members}>
      <MembersDispatchContext.Provider value={dispatch}>
        { children }
      </MembersDispatchContext.Provider>
    </MembersContext.Provider>
  );
};

const membersReducer = (members, action) => {
  switch (action.type) {
  case 'doctor_joined':
    return [
      {
        uid: action.uid,
        name: 'あなた',
        accepted: true,
        muted: false,
        mained: false,
      },
      // 入室待ちユーザー
      ...action.waitingUids.map(uid => {
        return {
          uid: uid,
          name: `患者${nextId++}`,
          accepted: false, // 医師が入室した時点で許可されているユーザーはいない
          muted: false,
          mained: false,
        };
      }),
    ];
  case 'doctor_accept':
    return members.map(m => {
      if (m.uid === action.uid) {
        return {
          ...m,
          accepted: true,
        };
      } else {
        return m;
      }
    });
  case 'patient_joined':
    return [
      ...members,
      {
        uid: action.uid,
        name: action.name || 'あなた',
        accepted: false,
        muted: false,
        mained: false,
      },
    ];
  case 'member_joined':
    // すでに追加済みの場合は何もしない
    if (members.some(m => m.uid === action.uid)) { console.log('なにもしない'); return [...members]; }

    return [
      ...members,
      {
        uid: action.uid,
        name: action.uid.startsWith('doctor') ? action.doctor_name || '医師' : (action.patient_name || '患者') + nextId++,
        accepted: action.accepted,
        muted: action.muted || false,
        mained: false,
      },
    ];
  case 'played_on_main':
    return members.map(m => {
      if (m.uid === action.uid) {
        return {
          ...m,
          mained: true,
        };
      } else {
        return {
          ...m,
          mained: false,
        };
      }
    });
  case 'member_muted':
    return members.map(m => {
      if (m.uid === action.uid) {
        return {
          ...m,
          muted: action.muted,
        };
      } else {
        return m;
      }
    });
  case 'member_left':
    return members.filter(m => m.uid !== action.uid);
  }
};

let nextId = 1;

MembersProvider.propTypes = {
  children: PropTypes.object,
};
