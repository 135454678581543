import React, { useContext, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  RootContext,
  MnButton,
  MnDialog,
  MnInlineMessage,
  AgoraApiV1TestsPath,
  useAgoraVideo,
  useAgoraChat,
} from '../../../index';
import { decycle } from 'json-cyclic';

const arrow = (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.21 21.36" className="c-mn-icon-arrow"><path d="M1.5,21.36A1.49,1.49,0,0,1,0,19.86,1.47,1.47,0,0,1,.45,18.8l8.26-8.12L.45,2.57A1.47,1.47,0,0,1,0,1.51,1.46,1.46,0,0,1,.44.44,1.5,1.5,0,0,1,1.5,0a1.47,1.47,0,0,1,1,.43l9.13,9a1.74,1.74,0,0,1,.53,1.25,1.72,1.72,0,0,1-.53,1.24l-9.13,9A1.47,1.47,0,0,1,1.5,21.36Z" className="c-c-icon-arrow-path"></path></svg>);

export const VideoTesting = () => {
  const context = useContext(RootContext);
  const [step, setStep] = useState(0);
  const [results, setResults] = useState([]);
  const user1 = useRef({});
  const user2 = useRef({});
  const [detail, setDetail] = useState();

  const uuidRef = useRef(null);
  if (uuidRef.current === null) {
    uuidRef.current = window.localStorage.getItem('uuid') || uuidv4();
    if (!window.localStorage.getItem('uuid')) {
      window.localStorage.setItem('uuid', uuidRef.current);
    }
  }

  const TEST_STEPS = [{
    id: 1,
    title: 'メディカルノート接続テスト',
    message: [
      'メディカルノートからレスポンスが正しく得られませんでした。',
      'ファイアウォール等の設定の確認の上、再度接続ボタンを押下してください。',
    ],
  }, {
    id: 2,
    title: 'デバイス一覧取得テスト',
    message: [
      '正常なデバイスを利用している場合には発生しないエラーです。',
      'デバイスが正しく接続されているか確認してください。',
    ],
  }, {
    id: 3,
    title: 'ローカルビデオ作成テスト',
    option: (<div id="local_stream" className="c-mn-card-contents__description p-test--stream"></div>),
    message: [
      'カメラ、マイクまたはスピーカーのデバイスの初期化でエラーが発生しました。',
      'デバイスの権限や正しく接続されているか確認してください。',
    ],
  }, {
    id: 4,
    title: 'チャットサービス接続テスト',
    message: [
      'チャットサービスとの接続に失敗しました。',
      'ファイアウォール等の設定の確認の上、再度接続ボタンを押下してください。',
    ],
  }, {
    id: 5,
    title: 'ビデオサービス接続テスト',
    message: [
      'ビデオサービスとの接続に失敗しました。',
      'ファイアウォール等の設定の確認の上、再度接続ボタンを押下してください。',
    ],
  }, {
    id: 6,
    title: 'ビデオ購読テスト',
    option: (<div id="main_stream" className="c-mn-card-contents__description p-test--stream"></div>),
    message: [
      'WebRTCによるビデオの購読に失敗しました。',
      'ファイアウォール等の設定の確認の上、再度接続ボタンを押下してください。',
    ],
  }, {
    id: 7,
    title: 'テスト完了',
  }].reverse();

  const checkStart = async () => {
    setStep(1);
    setResults([]);
    await step1();
  };

  const keepResult = (id, ok, value) => {
    setResults((r) => [
      ...r,
      { id: id, ok: ok, value: value },
    ]);
  };

  const video = useAgoraVideo({});
  const chat = useAgoraChat();

  const step1 = async () => {
    try {
      const res = await context.fetch(`${AgoraApiV1TestsPath()}?uuid=${uuidRef.current}`);
      user1.current = res.results[0];
      user2.current = res.results[1];
      context.onStateUpdate({ agora: user1.current });

      keepResult(1, true, res.meta);
      setStep(s => s + 1);
    } catch (err) {
      keepResult(1, false, err);
      return;
    }
    await step2();
  };

  const step2 = async () => {
    let devices;
    try {
      devices = await video.getDevices();

      keepResult(2, true, devices);
      setStep(s => s + 1);
    } catch (err) {
      keepResult(2, false, err);
      return;
    }
    await step3(devices.audio[0].id, devices.camera[0].id);
  };

  const step3 = async (audioId, cameraId) => {
    try {
      const tracks = await video.createTracks(audioId, cameraId);

      keepResult(3, true, decycle(tracks));
      setStep(s => s + 1);
      video.play('local_stream');
    } catch (err) {
      keepResult(3, false, err);
      return;
    }
    await step4();
  };

  const step4 = async () => {
    try {
      console.log('context', context);
      // TODO context.agoraがない
      const rs = await chat.join();
      await chat.sendMessage('hello world!');

      keepResult(4, true, decycle(rs.channel));
      setStep(s => s + 1);
    } catch (err) {
      keepResult(4, false, err);
      throw err;
      // return;
    }
    await step5();
  };

  const step5 = async () => {
    try {
      const rs = await video.join();
      video.play('local_stream');
      await video.publish();

      keepResult(5, true, rs);
      setStep(s => s + 1);
    } catch (err) {
      // throw err;
      keepResult(5, false, err);
      // return;
    }
    await step6();
  };

  const step6 = async () => {
    try {
      // const ev = this.state.agora.listenEvents().on('stream-added', ev => {
      //   this.state.agora.subscribe(ev.stream).then();
      // }).on('stream-subscribed', ev => {
      //   this.state.agora.forcePlay('main_stream', ev.stream);
      //   this.setState({
      //     result: [...this.state.result, { ok: true, value: decycle(ev) }, { ok: true, value: 'テスト完了しました' }],
      //     step: this.state.step + 1,
      //   });
      // });
      // const agora = new AgoraUtil(this.state.user2.uid, this.state.user2.video_id, this.state.user2.app_id, this.state.user2.chat_token);
      // this.setState({ main: agora });
      // new Promise((resolve, reject) => {
      //   agora.getDevice().then(() => {
      //     agora.createStream(true).then(() => {
      //       agora.joinVideo(this.state.user2.channel_name, 'dammy_stream').then(uid => {
      //         resolve(uid);
      //         setTimeout(() => {
      //           if (this.state.result.length < TEST_STEPS.length) {
      //             this.setState({ result: [...this.state.result, { ok: false, value: '60秒以内にビデオの購読が完了しませんでした。' }] });
      //           }
      //         }, 60000);

    } catch (err) {
      // throw err;
      keepResult(6, false, err);
    }
  };

  return (<div className="maw420px m-a">
    <h4 className="w90p c-mn-title-2 ta-c">オンライン診療接続テスト</h4>
    <MnInlineMessage mode="caution" messages={[
      'マイクやスピーカーの設定によってハウリングする可能性があります。',
      'スピーカーやヘッドフォンの音量にご注意ください。',
    ]} />
    <MnButton class="c-mn-btn--first mt-md" onClick={ async () => await checkStart() }><span>テスト開始</span></MnButton>
    { results.filter(r => !r.ok).map((r, i) => {
      return <MnInlineMessage key={i} messages={TEST_STEPS.find(s => s.id === r.id).message} />;
    })}
    { TEST_STEPS.map(test => {
      return test.id > step ? null : (<a
        key={test.id}
        className={ results.find(r => r.id === test.id)?.ok || false ? 'c-mn-card my-lg mx-16px' : 'c-mn-card my-lg mx-16px c-mn-card--red'}
        onClick={() => {
          setDetail(JSON.stringify(results.find(r => r.id === test.id)?.value, null, 2));
        }}
      >
        <div className="c-mn-card-contents">
          <div className="c-mn-card-main">
            <div className="c-mn-card-contents__name">
              <p>STEP{test.id}. {test.title}</p>
            </div>
            { results.find(r => r.id === test.id)?.ok && !!test.option ? test.option : null}
          </div>
        </div>
        {arrow}
      </a>);
    }) }
    <div>{ context.agora?.uid }</div>
    <MnDialog
      isOpen={!!detail}
      title="詳細"
      btnCloseHidden={true}
      btnSubmitLabel="OK"
      handleSubmit={() => {
        return new Promise(resolve => {
          setTimeout(() => {
            setDetail(undefined);
          }, 100);
          resolve();
        });
      }}
    >
      <div className="ov-s mah50vh"><pre>{detail}</pre></div>
    </MnDialog>
  </div>);
};
