import * as React from 'react';
import { RootContext } from '../../../index';

export const ExaminationTimes = () => {
  const context = React.useContext(RootContext);

  return (
    <div className='p-examination-times'>
      <div>診療</div>
      <div>{context.examinations.map(examination => {
        return (<div key={examination.code}>{examination.time} ({examination.minutes}分)</div>);
      })}</div>
    </div>
  );
};
