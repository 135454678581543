import * as React from 'react';
import { AgoraVideo } from '../AgoraVideo';
import {
  MembersProvider,
} from '../../../index';

export const TreatmentsExamination = () => {
  return (
    <MembersProvider>
      <AgoraVideo />
    </MembersProvider>
  );
};
