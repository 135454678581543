import { useSyncExternalStore, useCallback } from 'react';
import PropTypes from 'prop-types';

export const useContainerSize = ({ clientMarginWidth = 0, clientMarginHeight = 0, minWidth = 0, minHeight = 0, marginX = 0, marginY = 0, marginZ = 0 }) => {
  const subscribeWindowSizeChange = useCallback(
    (callback) => {
      window.addEventListener('resize', callback);

      return () => window.removeEventListener('resize', callback);
    },
    [clientMarginWidth, clientMarginHeight, minWidth, minHeight, marginX, marginY, marginZ],
  );

  const clientWidth = () => { return window.innerWidth - clientMarginWidth; };
  const clientHeight = () => { return window.innerHeight - clientMarginHeight; };
  const aspect = { w: 16, h: 9 };

  const getContainerWidth = () => {
    let width;
    if (condition()) {
      width = (clientHeight() - marginY) / aspect.h * aspect.w + marginX;
    } else {
      width = clientWidth();
    }
    return Math.max(width, minWidth);
  };

  const getContainerHeight = () => {
    let height;
    if (condition()) {
      height = clientHeight();
    } else {
      height = (clientWidth() - marginX) / aspect.w * aspect.h + marginY;
    }
    document.documentElement.style.setProperty('--videoHeight', Math.max(height, minHeight) - marginZ + 'px');
    return Math.max(height, minHeight);
  };

  const condition = () => {
    return (clientWidth() - marginX) / aspect.w > (clientHeight() - marginY) / aspect.h;
  };

  const width = useSyncExternalStore(subscribeWindowSizeChange, getContainerWidth);
  const height = useSyncExternalStore(subscribeWindowSizeChange, getContainerHeight);

  return { width: width, height: height };
};

useContainerSize.propTypes = {
  clientMarginWidth: PropTypes.number,
  clientMarginHeight: PropTypes.number,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  marginX: PropTypes.number,
  marginY: PropTypes.number,
  marginZ: PropTypes.number,
};
