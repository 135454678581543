import React, { useContext, useState, useRef, useEffect } from 'react';
import Modal from 'react-modal';
import {
  useAgoraChat,
  useAgoraVideo,
  useContainerSize,
  RootContext,
  SvgArrowIcon,
  SvgMicIcon,
  SvgMicMuteIcon,
  SvgCameraIcon,
  SvgCameraMuteIcon,
  SvgScreenIcon,
  SvgChatIcon,
  SvgShareIcon,
  SYSTEM_CALLS,
  Chat,
  VideoMembers,
  ConfirmModal,
  SharingUrl,
  MembersContext,
  MembersDispatchContext,
  useChatDisplayStatus,
} from '../../index';
import { ExaminationTimes } from './treatments/ExaminationTimes';

export const AgoraVideo = () => {
  const context = useContext(RootContext);
  const members = useContext(MembersContext);
  const dispatchMembers = useContext(MembersDispatchContext);

  // 診療済の場合は早期リターン
  if (['requested', 'examined', 'canceled'].indexOf(context.state) !== -1) { return null; }

  const MODAL_STATUSES = {
    PAUSING: 0,
    FINISHING: 1,
    ALERT: 2,
  };
  const ALERT_MODE = {
    RELOAD: 0, // リロード
    LIST: 1, // 一覧に戻る
    NONE: 2, // 何もしない
  };

  const mainTrackElementId = 'main_track';
  const agoraUser = useRef({ ...context.agora });
  const [isStarted, setIsStarted] = useState(false); // 診療中
  const [modalStatus, setModalStatus] = useState(null); // 表示中のモーダル
  const [isFullscreen, setIsFullscreen] = useState(false); // フルスクリーン
  const [isLoading, setIsLoading] = useState(false); // 右ペインのローディング表示
  const [isShareArea, setIsShareArea] = useState(false); // 共有URLエリア
  const [isAbleFinish, setIsAbleFinish] = useState(false); // 診療終了が可能か確認するフラグ
  const [openDeviceArea, setOpenDeviceArea] = useState(); // カメラ、オーディオの設定画面
  const [devices, setDevices] = useState({ audios: [], cameras: [], speekers: [] });
  const [alert, setAlert] = useState({ title: '', body: '', mode: ALERT_MODE.RELOAD });

  const chat = useAgoraChat({
    agoraUser: agoraUser,
    onMemberJoined: (uid) => {
      dispatchMembers({
        type: 'member_joined',
        uid: uid,
        accepted: false,
      });
    },
  });
  const video = useAgoraVideo({
    agoraUser: agoraUser,
    mainTrackElementId: mainTrackElementId,
    chatRenewToken: async () => {
      return await chat.renewToken();
    },
  });
  const containerSize = useContainerSize({
    clientMarginWidth: 360,
    clientMarginHeight: 80,
    minWidth: 680,
    minHeight: 612.5,
    marginY: 242,
    marginZ: 64,
  });
  const chatDisplayStatus = useChatDisplayStatus();

  const cameraPanel = useRef(null);
  const audioPanel = useRef(null);
  const sharePanel = useRef(null);

  // 「オンライン診療を開始する」
  const start = async () => {
    setIsLoading(true);
    try {
      agoraUser.current = await chat.checkState();
      const initDevices = await video.getDevices();
      setDevices(initDevices);
    } catch (e) {
      setIsLoading(false);
      showAlert({
        body: 'ビデオまたはオーディオデバイスの初期化に失敗しました。  \n設定を確認の上、OKボタンを押してください。',
        mode: ALERT_MODE.NONE,
      });
      return;
    }
    try {
      await chat.join();
      const memberUids = (await chat.getMemberUids()).filter(uid => uid !== agoraUser.current.uid);
      if (memberUids.some(uid => uid.startsWith('doctor'))) {
        await leave();
        showAlert({
          title: '',
          body: 'すでに医師が診療を開始している可能性があるため、再読み込みを行います。',
        });
        return;
      }
      dispatchMembers({
        type: 'doctor_joined',
        uid: agoraUser.current.uid,
        waitingUids: memberUids,
      });
      await video.join();
      setIsStarted(true);

      // 自分をサブ画面に初期表示
      video.playOnSub();
      await video.publish();

      const response = await updateState(context.api_location.examinations, { treatment: { id: context.code } });
      context.onStateUpdate({
        examination_id: response.result.code,
        state: response.result.treatment.state,
        state_i18n: response.result.treatment.state_i18n,
        worker: response.result.treatment.worker,
      });
      setIsAbleFinish(true);
    } catch {
      showAlert();
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * 診療ステータス更新
   * @param {string} url
   * @param {*} body
   * @param {string} methods
   */
  const updateState = async (url, body = {}, methods = 'POST') => {
    return await context.fetch(url, {
      methods: methods,
      body: body,
    });
  };

  /**
   * 共有URLエリアを表示・非表示
   * @param {*} e
   */
  const toggleShareUrl = (e) => {
    e.preventDefault();
    setIsShareArea(!isShareArea);
  };

  /**
   * アラートモーダルの表示
   */
  const showAlert = ({ title = '通信でエラーが発生しました', body = 'OKを押すと一覧画面に戻ります。', mode = ALERT_MODE.RELOAD } = {}) => {
    setAlert({ title: title, body: body, mode: mode });
    setModalStatus(MODAL_STATUSES.ALERT);
  };

  const closeAlert = async () => {
    context.exception(new Error(alert.body || alert.title));
    switch (alert.mode) {
    case ALERT_MODE.RELOAD: location.reload(); break;
    case ALERT_MODE.LIST: location.href = context.url_location.index; break;
    case ALERT_MODE.NONE: setModalStatus(); break;
    }
  };

  /**
   * 診療の中断
   */
  const pause = async () => {
    await chat.systemCall(SYSTEM_CALLS.PAUSE);
    await leave();

    try {
      await updateState(`${context.api_location.examinations}/${context.examination_id}`, {}, 'PATCH');
      location.reload();
    } catch (e) {
      showAlert();
    }
  };

  /**
   * 「終了する」
   */
  const onFinish = async () => {
    setModalStatus(MODAL_STATUSES.FINISHING);
  };

  /**
   * 診療の終了
   */
  const finish = async () => {
    await chat.systemCall(SYSTEM_CALLS.FINISH);
    await leave();

    try {
      await updateState(context.api_location.diagnoses, { examination: { id: context.examination_id } });
      location.href = context.url_location.index + '?history=1';
    } catch (e) {
      showAlert();
    }
  };

  const leave = async () => {
    try {
      await chat.leave();
      await video.leave();
    } catch (e) {
      // 何もしない
    }
  };

  // オーディオとカメラの設定画面ON/OFF
  const toggleDeviceArea = (area) => {
    area === openDeviceArea ? setOpenDeviceArea(null) : setOpenDeviceArea(area);
  };

  const renderWaiting = () => {
    const isRequested = ['requested'].indexOf(context.state) !== -1;
    const isCompleted = ['examined', 'canceled'].indexOf(context.state) !== -1;
    return (
      <div className='p-center-area'>
        { isRequested ? (
          <div>
            <p className="fz24">診療日を確定してください</p>
            <a className="c-mn-btn--first" onClick={ () => { context.onStateUpdate({ isOpenModalWaitingExaminationConfirm: true }); } }><span>確定する</span></a>
            <a className="p-xl" href="/treatments?history=1"><span>患者一覧に戻る</span></a>
          </div>
        ) : isCompleted ? (
          <div>
            <p className="fz24">診療は完了しました。</p>
            <a className="p-xl" href="/treatments?history=1"><span>患者一覧に戻る</span></a>
          </div>
        ) : (
          <div>
            <p className="fz24">患者が診療待ちです。</p>
            <a className="c-mn-btn--green" onClick={start}><span>オンライン診療を開始する</span></a>
            <div className="mt-lg">
              <SharingUrl onClose={toggleShareUrl} />
            </div>
            <a className="p-xl" href="/treatments?history=1"><span>患者一覧に戻る</span></a>
          </div>
        )}
      </div>
    );
  };

  const videoClass = () => {
    let c = 'p-video';
    if (isFullscreen) {
      c += ' hide';
    } else if (isLoading) {
      c += ' pos-r p-loading p-loading--2x';
    } else {
      c += ' pos-r';
    }
    return c;
  };

  /**
   * カメラ停止/開始
   */
  const onMuteVideo = async () => {
    await video.muteVideo();
  };

  /**
   * マイクミュート/解除
   */
  const onMuteAudio = async () => {
    await video.muteAudio();
  };

  // 画面共有/共有解除
  const onShareScreen = async (e) => {
    e.preventDefault();
    await video.shareScreen();
  };

  /**
   * カメラデバイスの切り替え
   */
  const changeCameraDevice = async (e) => {
    const cameraId = e.target.value;
    await video.setCameraDevice(cameraId);
  };

  /**
   * マイクデバイスの切り替え
   */
  const changeAudioDevice = async (e) => {
    const audioId = e.target.value;
    await video.setAudioDevice(audioId);
  };

  /**
   * スピーカーデバイスの切り替え
   */
  const changeSpeekerDevice = async (e) => {
    const speekerId = e.target.value;
    await video.setSpeekerDevice(speekerId);
  };

  const onFullscreen = () => {
    setIsFullscreen(!isFullscreen);
    document.body.className = isFullscreen ? '' : 'full';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', async () => { await leave(); });
    // リサイズイベント取得
    Modal.setAppElement('body');
  }, []);

  return (
    <div className={videoClass()} style={containerSize}>
      {
        isStarted
          ? <div className={ isFullscreen ? 'p-video--full' : 'p-video--normal' }>
            <VideoMembers
              agoraUser={ agoraUser }
              video={ video }
              chat={ chat }
              size= { 4 }
              isFullscreen={ isFullscreen }
              onFullscreen={ onFullscreen }
            />
            <div className="p-video-container">
              <div>
                <div className="p-screen">
                  <div id={ mainTrackElementId } className='p-main-video'>
                    { members.find(m => m.mained)?.muted && <div className="p-video--videomute">{SvgCameraIcon}</div> }
                  </div>
                  <div className='p-menu-option'>
                    <div>
                      {
                        openDeviceArea === 'camera' &&
                      <div style={{ left: cameraPanel.current?.offsetLeft }} className='p-menu-option__item p-menu-option__item__bg'>
                        <p className="p-menu-option__item-label">カメラ</p>
                        {devices.cameras.map(item => {
                          return (
                            <div key={item.id} className="p-menu-option__item-option">
                              <input type="radio" name="camera" id={`camera-${item.id}`} value={item.id} checked={item.id === video.activeCameraId} onChange={changeCameraDevice} />
                              <label htmlFor={`camera-${item.id}`}>{item.label}</label>
                            </div>
                          );
                        })}
                      </div>
                      }
                      {
                        openDeviceArea === 'audio' &&
                      <div style={{ left: audioPanel.current?.offsetLeft }} className='p-menu-option__item p-menu-option__item__bg'>
                        <p className="p-menu-option__item-label">マイク</p>
                        {devices.audios.map(item => {
                          return (
                            <div key={item.id} className="p-menu-option__item-option">
                              <input type="radio" name="mic" id={`mic-${item.id}`} value={item.id} checked={item.id === video.activeAudioId} onChange={changeAudioDevice} />
                              <label htmlFor={`mic-${item.id}`}>{item.label}</label>
                            </div>
                          );
                        })}
                        <hr />
                        <p className="p-menu-option__item-label">スピーカー</p>
                        {devices.speekers.map(item => {
                          return (
                            <div key={item.id} className="p-menu-option__item-option">
                              <input type="radio" name="speeker" id={`speeker-${item.id}`} value={item.id} checked={item.id === video.activeSpeekerId} onChange={changeSpeekerDevice} />
                              <label htmlFor={`speeker-${item.id}`}>{item.label}</label>
                            </div>
                          );
                        })}
                      </div>
                      }
                      {
                        isShareArea &&
                      <div style={{ left: sharePanel.current?.offsetLeft - 90 }} className='p-menu-option__item'>
                        <SharingUrl onClose={toggleShareUrl} />
                      </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-menu-area">
              <div className="level-left">
                <div ref={audioPanel} className="level-item has-text-centered p-menu-area__panel">
                  <a onClick={onMuteAudio} className="fz12 p-menu-area__panel-button p-menu-area__panel-button--w80">
                    { video.isMuteAudio ? SvgMicIcon : SvgMicMuteIcon }
                    <p className="fz12">{video.isMuteAudio ? 'ミュート解除' : 'ミュート'}</p>
                  </a>
                  <a onClick={() => toggleDeviceArea('audio')} className="fz12 p-menu-area__panel-button p-menu-area__panel-arrow">{ SvgArrowIcon }</a>
                </div>
                <div ref={cameraPanel} className="level-item has-text-centered p-menu-area__panel">
                  <a onClick={onMuteVideo} className="fz12 p-menu-area__panel-button">
                    { video.isMuteVideo ? SvgCameraIcon : SvgCameraMuteIcon }
                    <p className="fz12">{video.isMuteVideo ? 'カメラ開始' : 'カメラ停止'}</p>
                  </a>
                  <a onClick={() => toggleDeviceArea('camera')} className="fz12 p-menu-area__panel-button p-menu-area__panel-arrow">{ SvgArrowIcon }</a>
                </div>
              </div>
              <div className="level mb0">
                <div ref={sharePanel} className="level-item has-text-centered p-menu-area__panel">
                  <a onClick={toggleShareUrl} className="fz12 p-menu-area__panel-button">
                    { SvgShareIcon }
                    <p className="fz10">共有URL</p>
                  </a>
                </div>
                <div className="level-item has-text-centered p-menu-area__panel">
                  <button onClick={onShareScreen} className="fz12 p-menu-area__panel-button" disabled={!isAbleFinish}>
                    { SvgScreenIcon }
                    <p className="fz12">{video.isShareScreen ? '共有解除' : '画面共有'}</p>
                  </button>
                </div>
                <div className="level-item has-text-centered p-menu-area__panel">
                  <a onClick={chatDisplayStatus.open} className={`fz12 p-menu-area__panel-button ${ chatDisplayStatus.unreadBadge ? ' unread' : '' }`}>
                    { SvgChatIcon }
                    <p className="fz12">チャット</p>
                  </a>
                </div>
              </div>
              <div className="level-right">
                <div className="level-item has-text-centered p-menu-area__panel">
                  <button onClick={ () => setModalStatus(MODAL_STATUSES.PAUSING) } className="p-menu-area__panel-button p-menu-area__panel--stey" disabled={!isAbleFinish}>
                    <p className="fz14 fw-br">中断する</p>
                  </button>
                </div>
                <div className="level-item has-text-centered p-menu-area__panel">
                  <button onClick={onFinish} className="p-menu-area__panel-button p-menu-area__panel--finish" disabled={!isAbleFinish}>
                    <p className="fz14 fw-br">終了する</p>
                  </button>
                </div>
              </div>
            </div>
            {
              !isLoading &&
            <Chat
              chat={ chat }
              agoraUser={ agoraUser }
              isFullscreen={ isFullscreen }
              chatDisplayStatus={ chatDisplayStatus }
              allowMinimum={ true }
            />
            }
            <form name="finish" action="/diagnoses" method="POST">
              {/* TODO: どこでセットしている？stagingも入ってない */}
              <input type="hidden" name="authenticity_token" value={context.csrfToken} />
              <input type="hidden" name="examination_id" value={context.code} />
            </form>
          </div>
          : renderWaiting()
      }

      {
        !isFullscreen &&
        <ExaminationTimes />
      }
      <ConfirmModal
        isOpen={ modalStatus === MODAL_STATUSES.PAUSING }
        title='この診療を中断しますか？'
        body='診療を中断すると、診療待ちの画面に戻ります。'
        onOk={ pause }
        onCancel={ async () => setModalStatus() }
        option={
          { ok: '中断する' }
        }
      />
      <ConfirmModal
        isOpen={ modalStatus === MODAL_STATUSES.FINISHING }
        title='この診療を終了しますか？'
        body='診療を終了すると、患者一覧画面に遷移します。'
        onOk={ finish }
        onCancel={ async () => setModalStatus() }
        option={
          { ok: '終了する' }
        }
      />
      <ConfirmModal
        isOpen={ modalStatus === MODAL_STATUSES.ALERT }
        title={ alert.title }
        body={ alert.body }
        onOk={ closeAlert }
        option={
          {
            ok: '閉じる',
            showCancel: false,
            classOK: 'c-mn-btn c-mn-btn--basic-s c-mn-btn--conversion c-mn-btn--compact js-modal-dialog02-close',
          }
        }
      />
    </div>
  );
};
