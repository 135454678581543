import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import {
  RootContext,
  MnButton,
} from '../../index';

// TODO
// モーダル表示
export const ConfirmModal = ({ isOpen = false, title = '', body = '', onOk = () => {}, onCancel = () => {}, option = {} }) => {
  const context = React.useContext(RootContext);
  option = { ...{ showOK: true, showCancel: true, ok: 'OK', cancel: context.t ? context.t('Agora.cancel') : 'キャンセル', classOK: '', classCancel: '' }, ...option };

  return (
    <Modal
      isOpen={isOpen}
      className="c-mn-modal-dialog is-active"
      overlayClassName="c-mn-modal is-active--dialog"
    >
      <p className="c-mn-modal-dialog__title">{title}</p>
      <div className="c-mn-modal-dialog__txt">{ context.formatSimple(body) }</div>
      <div className="c-mn-modal-dialog__btn-wrap d-fx jc-fe">
        { option.showCancel ? (<MnButton class={ option.classCancel ? option.classCancel : 'c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact js-modal-dialog02-close mr-md'} onClick={onCancel}>
          <span>{option.cancel}</span>
        </MnButton>) : '' }
        { option.showOK ? (<MnButton class={ option.classOK ? option.classOK : 'c-mn-btn c-mn-btn--basic-s c-mn-btn--first c-mn-btn--compact js-modal-dialog02-close'} onClick={onOk}>
          <span>{option.ok}</span>
        </MnButton>) : '' }
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  option: PropTypes.object,
};
