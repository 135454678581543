export const AgoraErrCode = {
  InputDevice: 'E0001',
  InitLocalTrack: 'E0002',
  InitClient: 'E0003',
  PublishVideo: 'E0004',
  ElementId: 'E0005',
  SubscribeTrack: 'E0006',
  JoinChatChannel: 'E0007',
  LoginChat: 'E0008',
  LeaveChat: 'E0009',
  LogoutChat: 'E0010',
  SetDeviceOutput: 'E0011',
  SetDeviceInput: 'E0012',
  Mute: 'E0013',
  LeaveVideo: 'E0014',
};

const AgoraErrMessage = {
  E0001: 'input device not found.',
  E0002: 'init local track failed.',
  E0003: 'init failed.',
  E0004: 'publish video track failed.',
  E0005: 'element id is empty.',
  E0006: 'subscribe track failed.',
  E0007: 'chat channel join failed',
  E0008: 'chat login failed.',
  E0009: 'chat channel leave failed.',
  E0010: 'chat logout failed.',
  E0011: 'set output device switch failed.',
  E0012: 'set input device switch failed.',
  E0013: 'mute or unmute failed.',
  E0014: 'video channel leave failed.',
};

export const getAgoraErrorMessage = (code, detail = null) => {
  return { code: code, message: AgoraErrMessage[code], detail: detail };
};
