import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  SvgChatOpenIcon,
  SvgChatMinimumIcon,
  SvgChatCloseIcon,
  MnButton,
  SvgChatSendDisableIcon,
  SvgChatSendIcon,
  MembersContext,
} from '../../index';

// チャットモーダル表示
export const Chat = ({ chat, agoraUser, chatDisplayStatus, isFullscreen = false, allowMinimum = false, headerText = 'チャット', placeHolderText = 'メッセージを入力' }) => {
  const [text, setText] = useState(''); // 現在のテキスト
  const [logs, setLogs] = useState([]);
  const members = useContext(MembersContext);

  useEffect(() => {
    const callback = (msg, senderUid) => {
      setLogs((l) => [
        ...l,
        {
          id: l.length + 1,
          senderUid: senderUid,
          message: msg,
        },
      ]);
      chatDisplayStatus.onReceive();
    };
    chat.onReceiveMessage(callback);

    return () => chat.removeOnReceiveMessageListener(callback);
  }, [chat.joined]);

  const sendMessage = async () => {
    if (diableSending) return;

    await chat.sendMessage(text);
    setLogs([
      ...logs,
      {
        id: logs.length + 1,
        senderUid: agoraUser.current.uid,
        message: text,
      },
    ]);
    setText('');
  };

  /**
   * キーボード操作でメッセージ送信
   * @param {*} e KeyboardEvent
   */
  const onKeyupSendMessage = async (e) => {
    if (e.key === 'Enter' && e.ctrlKey) { // Ctrl+Enter
      await sendMessage();
    }
  };

  const diableSending = !(chat.joined && text);

  return (
    <div className={ (isFullscreen && chatDisplayStatus.isOpen) || (!isFullscreen && !chatDisplayStatus.isClose) ? 'p-chat' : 'd-n' }>
      <div className={`p-chat--head ${chatDisplayStatus.unreadBadge ? 'unread' : ''}`}>
        <span>{headerText}</span>
        { allowMinimum && <button onClick={chatDisplayStatus.toggletMinimum}>{ chatDisplayStatus.isMinimum ? SvgChatOpenIcon : SvgChatMinimumIcon }</button> }
        <button onClick={chatDisplayStatus.close}>{ SvgChatCloseIcon }</button>
      </div>
      {
        !chatDisplayStatus.isMinimum &&
        <React.Fragment>
          <div className='p-chat--body'>
            {
              logs.map(log => {
                return (
                  <div key={log.id} className="p-chat--body__message">
                    <div className="p-chat--body__author">{members.find(member => (member.uid === log.senderUid))?.name}</div>
                    <div className="p-chat--body__message">{log.message}</div>
                  </div>
                );
              })
            }
          </div>
          <div className='p-chat--foot'>
            <div className="p-chat-input">
              <div className="p-chat-input__form">
                <textarea
                  name="message"
                  placeholder={ placeHolderText }
                  disabled={ !chat.joined }
                  value={ text }
                  onChange={ (e) => setText(e.target.value) }
                  onKeyUp={ onKeyupSendMessage }
                  className="c-mn-input-text pr-xl"
                />
                <MnButton
                  class={diableSending ? 'p-chat-input__form--disabled' : 'p-chat-input__form--btn'}
                  disabled={diableSending}
                  onClick={sendMessage}
                >
                  <span>{diableSending ? SvgChatSendDisableIcon : SvgChatSendIcon }</span>
                </MnButton>
              </div>
            </div>
          </div>
        </React.Fragment>
      }
    </div>
  );
};

Chat.propTypes = {
  chat: PropTypes.object.isRequired,
  agoraUser: PropTypes.object.isRequired,
  chatDisplayStatus: PropTypes.object.isRequired,
  isFullscreen: PropTypes.bool,
  allowMinimum: PropTypes.bool,
  headerText: PropTypes.string,
  placeHolderText: PropTypes.string,
};
