import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  RootContext,
  SvgFullscreenIcon,
  SvgNormalscreenIcon,
  SvgMoreIcon,
  SvgZoomIcon,
  SvgCameraIcon,
  SvgArrowPrevIcon,
  SvgArrowNextIcon,
  MembersContext,
  MembersDispatchContext,
  SYSTEM_CALLS,
} from './index.js';

export const VideoMembers = ({ video, chat, agoraUser = {}, size = 3, isFullscreen = false, isSP = false, onFullscreen }) => {
  const context = useContext(RootContext);
  const members = useContext(MembersContext);
  const dispatchMembers = useContext(MembersDispatchContext);

  const [activeArea, setActiveArea] = useState(0);
  const [showKickLinkMemberUid, setShowKickLinkMemberUid] = useState(null);
  const [borderStyles, setBorderStyles] = useState({});
  const isDoctor = agoraUser.current?.uid?.startsWith('doctor');

  // 入室拒否
  const rejectMember = async (uid) => {
    await chat.systemCall(SYSTEM_CALLS.REJECT, uid);
    dispatchMembers({
      type: 'member_left',
      uid: uid,
    });
  };

  // 入室許可
  const acceptMember = async (uid) => {
    if (!isDoctor) return;
    await chat.systemCall(SYSTEM_CALLS.ACCEPT, uid);
    dispatchMembers({
      type: 'doctor_accept',
      uid: uid,
    });
    // まだpublishされていないので、ここではplayできない
  };

  // 強制退出
  const kickMember = async (uid) => {
    if (!isDoctor) return;
    await chat.systemCall(SYSTEM_CALLS.KICK, uid);
  };

  const setStyle = () => {
    const s = members.reduce((styles, m) => {
      const vol = video.getVolumeLevel(m.uid);
      return { ...styles, [m.uid]: `solid 1px #33${('000' + Math.min(Math.floor(vol * 512), 255).toString(16)).slice(-2)}33` };
    }, {});
    setBorderStyles(s);
  };

  const onMemberPrev = () => {
    if (activeArea === 0) {
      setActiveArea(Math.floor(members.length / 4));
    } else {
      setActiveArea(activeArea - 1);
    }
  };

  const onMemberNext = () => {
    if (activeArea === Math.floor(members.length / 4)) {
      setActiveArea(0);
    } else {
      setActiveArea(activeArea + 1);
    }
  };

  const zoomMember = (uid) => {
    video.playOnMain(uid);
  };

  const toggleKickLink = (uid) => {
    uid === showKickLinkMemberUid ? setShowKickLinkMemberUid(null) : setShowKickLinkMemberUid(uid);
  };

  const areas = [].concat.apply([], (members || []).map((member, i) => {
    return i % size ? [] : [members.slice(i, i + size)];
  }));
  const index = areas.length <= activeArea ? 0 : activeArea;

  useEffect(() => {
    const interval = setInterval(() => {
      setStyle();
    }, 100);

    return () => clearInterval(interval);
  }, [members]);

  return (
    <div className={ isSP ? 'p-member-area-sp' : 'p-member-area'}>
      { isDoctor && members.length > size &&
        <div className="p-member-area--prev">
          <button onClick={onMemberPrev}>{ SvgArrowPrevIcon }</button>
        </div>
      }
      <div className="p-member-area--list">
        {areas.map((area, i) => {
          return (
            <div key={`area-${i}`} className={index === i ? 'p-member-area--card-area' : 'd-n' }>
              {area.map(member => {
                return (
                  <div key={member.uid} className={member.mained ? 'd-n' : ''}>
                    { member.accepted ? (
                      <div id={member.uid} style={{ border: borderStyles[member.uid] || 'solid 1px #333' }} className="p-member-area--card">
                        { member.muted &&
                          <div className="p-member-area--card--videomute">{ SvgCameraIcon }</div>
                        }
                        <div className="p-member-area--card--btn">
                          <button onClick={ () => { zoomMember(member.uid); } }>{ SvgZoomIcon }</button>
                          {
                            isDoctor && (member.uid !== agoraUser.current.uid) &&
                            <button onClick={ () => { toggleKickLink(member.uid); } }>{ SvgMoreIcon }</button>
                          }
                        </div>
                        {
                          isDoctor && (member.uid !== agoraUser.current.uid) && (showKickLinkMemberUid === member.uid) &&
                          <div className='p-member-area--card__kick'>
                            <p><a onClick={ () => { kickMember(member.uid); } }>強制退室させる</a></p>
                          </div>
                        }
                      </div>
                    ) : isDoctor ? (
                      <div id={member.uid} className="p-member-area--card">
                        <p>{member.name}が入室しようとしています。</p>
                        <div className="p-member-area--card--invate">
                          <a className="c-mn-btn c-mn-btn--basic-s c-mn-btn--third c-mn-btn--compact js-modal-dialog02-close" onClick={() => { rejectMember(member.uid); }}>
                            <span>拒否</span>
                          </a>
                          <a className="c-mn-btn c-mn-btn--basic-s c-mn-btn--green c-mn-btn--compact js-modal-dialog02-close" onClick={() => { acceptMember(member.uid); }}>
                            <span>許可</span>
                          </a>
                        </div>
                      </div>
                    ) : null }
                  </div>
                );
              })}
            </div>
          );
        })}
        {
          isDoctor || isSP ? null : (
            <div className="p-member-area--language">
              <div>
                { context.lang === 'ja' ? 'JP' : (<a onClick={ () => {
                  context.language('ja');
                }}>JP</a>) }
                &nbsp;/&nbsp;
                { context.lang !== 'ja' ? 'EN' : (<a onClick={ () => {
                  context.language('en');
                }}>EN</a>) }
              </div>
            </div>
          )
        }
      </div>
      {
        !isDoctor && members.length > size &&
        <div className="p-member-area--prev">
          <button onClick={onMemberPrev}>{ SvgArrowPrevIcon }</button>
        </div>
      }
      {
        members.length > size &&
        <div className="p-member-area--next">
          <button onClick={onMemberNext}>{ SvgArrowNextIcon }</button>
        </div>
      }
      {
        onFullscreen &&
          <div className='p-menu-option__zoom'>
            <a onClick={onFullscreen}>
              { isFullscreen ? SvgNormalscreenIcon : SvgFullscreenIcon }
            </a>
          </div>
      }
    </div>
  );
};

VideoMembers.propTypes = {
  video: PropTypes.object.isRequired,
  chat: PropTypes.object.isRequired,
  agoraUser: PropTypes.object,
  size: PropTypes.number,
  isDoctor: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  isSP: PropTypes.bool,
  onFullscreen: PropTypes.func,
};
