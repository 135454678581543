import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { RootContext, SvgShareCloseIcon } from '../../index';

// 共有URL
export const SharingUrl = ({ onClose, shareLinkText = '共有URL', copyText = 'URLをコピー', copiedText = '共有URLをコピーしました。' }) => {
  const [copied, setCopied] = useState(false); // 旧isCopy
  const context = useContext(RootContext);

  const copy = (newClipText) => {
    const isiOS = navigator.userAgent.match(/ipad|iphone/i);
    // iOS は copy イベントを利用できないため、copy イベントを使わない方法で実装
    if (isiOS) {
      var textArea = document.createElement('textArea');
      textArea.value = newClipText;
      document.body.appendChild(textArea);

      var range = document.createRange();
      range.selectNodeContents(textArea);

      var selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      textArea.setSelectionRange(0, 999999);
      document.execCommand('copy');
      document.body.removeChild(textArea);

      setCopied(true);
      setTimeout(() => {
        setCopied(() => false);
      }, 6000);
    } else {
      navigator.clipboard.writeText(newClipText);
      setCopied(true);
      setTimeout(() => {
        setCopied(() => false);
      }, 6000);
    }
  };

  return (
    <div className="p-sharing-url">
      <div className="p-sharing-url--btn">
        <div>{shareLinkText}</div>
        <button onClick={ () => { copy(context?.url_location?.share); } }><span>{copyText}</span></button>
      </div>
      <div className="p-sharing-url--close"><a onClick={ onClose }>{ SvgShareCloseIcon }</a></div>
      {context.formatSimple(context?.url_location?.share)}
      {copied && createPortal(
        <div className="c-mn-toast-wrap">
          <div className='c-mn-toast fadein-out'>{copiedText}</div>
        </div>,
        document.body,
      )}
    </div>
  );
};

SharingUrl.propTypes = {
  onClose: PropTypes.func.isRequired,
  shareLinkText: PropTypes.string,
  copyText: PropTypes.string,
  copiedText: PropTypes.string,
};
