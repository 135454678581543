import { useContext, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AgoraRTC from 'agora-rtc-sdk-ng';
import {
  RootContext,
  MembersContext,
  MembersDispatchContext,
  AgoraErrCode,
  getAgoraErrorMessage,
} from '../../index';

export const useAgoraVideo = ({ agoraUser, mainTrackElementId, chatRenewToken }) => {
  const context = useContext(RootContext);
  const members = useContext(MembersContext);
  const membersRef = useRef(null);
  membersRef.current = members; // イベントリスナー内で参照する場合はこちらを使う
  const dispatchMembers = useContext(MembersDispatchContext);
  const client = useRef(null);
  const localVideoTrack = useRef(null);
  const localAudioTrack = useRef(null);
  const [activeAudioId, setActiveAudioId] = useState(null);
  const [activeCameraId, setActiveCameraId] = useState(null);
  const [activeSpeekerId, setActiveSpeekerId] = useState(null);
  const [isMuteAudio, setIsMuteAudio] = useState(false); // マイクミュート
  const [isMuteVideo, setIsMuteVideo] = useState(false); // ビデオミュート
  const [isShareScreen, setIsShareScreen] = useState(false); // 画面共有

  /**
   * デバイスの取得
   */
  const getDevices = async () => {
    const audios = [];
    const cameras = [];
    const speekers = [];
    const deviceInfo = await AgoraRTC.getDevices();
    deviceInfo.forEach(info => {
      const attr = {
        id: info.deviceId,
        label: info.label,
      };
      switch (info.kind) {
      case 'audioinput':
        audios.push(attr);
        break;
      case 'videoinput':
        cameras.push(attr);
        break;
      case 'audiooutput':
        speekers.push(attr);
        break;
      }
    });

    if (!audios.length || !cameras.length) {
      throw getAgoraErrorMessage(AgoraErrCode.InputDevice);
    }

    // 使用するデバイスの初期選択
    setActiveAudioId(audios[0].id);
    setActiveCameraId(cameras[0].id);
    setActiveSpeekerId(speekers[0]?.id);

    return {
      audios: audios,
      cameras: cameras,
      speekers: speekers,
    };
  };

  const setAudioDevice = async (audioId) => {
    try {
      await localAudioTrack.current.setDevice(audioId);
    } catch (e) {
      throw getAgoraErrorMessage(AgoraErrCode.SetDeviceInput, e);
    }
    setActiveAudioId(audioId);
  };

  const setCameraDevice = async (cameraId) => {
    try {
      await localVideoTrack.current.setDevice(cameraId);
    } catch (e) {
      throw getAgoraErrorMessage(AgoraErrCode.SetDeviceInput, e);
    }
    setActiveCameraId(cameraId);
  };

  const setSpeekerDevice = async (speekerId) => {
    try {
      await Promise.all(
        client.current.remoteUsers.map(user => {
          // This method supports Chrome and Edge on desktop devices only. Other browsers throw a NOT_SUPPORTED error when calling this method.
          user.audioTrack?.setPlaybackDevice(speekerId);
        }),
      );
    } catch (e) {
      throw getAgoraErrorMessage(AgoraErrCode.SetDeviceOutput, e);
    }
    setActiveSpeekerId(speekerId);
  };

  const createTracks = async () => {
    localAudioTrack.current = await createAudioTrack();
    localVideoTrack.current = await createVideoTrack();

    return [localAudioTrack.current, localVideoTrack.current];
  };

  const createAudioTrack = async () => {
    return await AgoraRTC.createMicrophoneAudioTrack({
      microphoneId: activeAudioId,
    });
  };

  const createVideoTrack = async () => {
    const track = await AgoraRTC.createCameraVideoTrack({
      cameraId: activeCameraId,
      encoderConfig: '720p',
    });
    track.on('track-ended', async () => {
      await client.current.unpublish([localVideoTrack.current]);
      localVideoTrack.current.close();
    });

    return track;
  };

  const createShareScreenTrack = async () => {
    const track = await AgoraRTC.createScreenVideoTrack();
    track.on('track-ended', async () => {
      // ブラウザの共有停止ボタンを押すなど、何らかの理由で停止した場合はカメラを映す
      const newTrack = await createVideoTrack();
      await newTrack.setMuted(localVideoTrack.current.muted);

      await client.current.unpublish([localVideoTrack.current]);
      localVideoTrack.current.close();

      localVideoTrack.current = newTrack;
      const me = membersRef.current.find(u => u.uid === client.current.uid);
      if (me) {
        me.mained ? playOnMain() : playOnSub();
      }
      await client.current.publish([newTrack]);

      setIsShareScreen(false);
    });

    return track;
  };

  // ビデオに入室
  const join = async () => {
    client.current = AgoraRTC.createClient({
      mode: 'rtc', // 配信モード
      codec: 'vp8', // コーデック
    });

    setEventHandlers();
    try {
      await createTracks();
    } catch (e) {
      throw getAgoraErrorMessage(AgoraErrCode.InitLocalTrack, e);
    }

    try {
      await client.current.join(
        agoraUser.current.app_id, // APPID
        agoraUser.current.channel_name, // channel
        agoraUser.current.video_id, // token
        agoraUser.current.uid, // uid
      );
    } catch (e) {
      throw getAgoraErrorMessage(AgoraErrCode.InitClient, e);
    }
  };

  const setEventHandlers = () => {
    // リモートユーザーのjoin（自分がjoinしたとき、すでにjoin済みの各ユーザー分発火）
    client.current.on('user-joined', (remoteUser) => {
      if (!client.current.uid.startsWith('doctor')) {
        dispatchMembers({
          type: 'member_joined',
          uid: remoteUser.uid,
          accepted: true, // すでに許可されている人のみビデオに入れる
          doctor_name: context.t('Agora.doctor'),
          patient_name: context.t('Agora.patient'),
        });
      }
    });

    // リモートユーザーのpublish
    client.current.on('user-published', async (remoteUser, mediaType) => {
      try {
        await client.current.subscribe(remoteUser, mediaType);
      } catch (e) {
        throw getAgoraErrorMessage(AgoraErrCode.SubscribeTrack, e);
      }
      if (mediaType === 'video' || mediaType === 'all') {
        // カメラの再開でも発火
        if (!membersRef.current.some(m => m.mained) || membersRef.current.find(m => m.uid === remoteUser.uid && m.mained)) {
          // メインで表示中のユーザーがいない、またはpublishしたユーザーをすでにメインで表示中の場合
          playOnMain(remoteUser.uid);
        } else {
          playOnSub(remoteUser.uid);
        }
      }
      if (mediaType === 'audio' || mediaType === 'all') {
        remoteUser.audioTrack.play();
      }
    });

    client.current.on('user-info-updated', (uid, msg) => {
      switch (msg) {
      case 'mute-video':
        dispatchMembers({
          type: 'member_muted',
          uid: uid,
          muted: true,
        });
        break;
      case 'unmute-video':
        dispatchMembers({
          type: 'member_muted',
          uid: uid,
          muted: false,
        });
        break;
      }
    });

    client.current.on('token-privilege-will-expire', async () => {
      // RtmClientにはeventがないためvideo側で検知する
      const newToken = await chatRenewToken();
      await client.current.renewToken(newToken);
    });

    // 旧peer-leaveはここにくる？
    client.current.on('user-left', (remoteUser, reason) => {
      remoteUser.videoTrack?.stop(); // 退出したメンバーの映像を停止
    });
  };

  /**
   * 配信の再生（メイン画面）
   * @param {string, null} uid
   */
  const playOnMain = (uid = client.current.uid) => {
    let videoTrack;
    if (uid === client.current.uid) {
      videoTrack = localVideoTrack.current;
    } else {
      // リモートユーザーの場合
      const remoteUser = client.current.remoteUsers.find(u => u.uid === uid);
      if (!remoteUser) { return; }
      videoTrack = remoteUser.videoTrack;
    }

    // 現在メインで表示しているユーザーはサブに表示
    const mainMember = members.find(m => m.mained);
    if (mainMember) { playOnSub(mainMember.uid); }

    if (videoTrack) {
      videoTrack.isPlaying && videoTrack.stop();
      if (!mainTrackElementId) throw getAgoraErrorMessage(AgoraErrCode.ElementId);
      videoTrack.play(mainTrackElementId);
    }

    dispatchMembers({
      type: 'played_on_main',
      uid: uid,
    });
  };

  /**
   * 配信の再生（サブ画面）
   * @param {string, null} uid
   */
  const playOnSub = (uid = client.current.uid) => {
    let videoTrack;
    if (uid === client.current.uid) {
      videoTrack = localVideoTrack.current;
    } else {
      // リモートユーザーの場合
      videoTrack = client.current.remoteUsers.find(u => u.uid === uid)?.videoTrack;
    }

    if (!videoTrack) { return; }

    videoTrack.isPlaying && videoTrack.stop();
    videoTrack.play(uid);
  };

  // 配信開始
  const publish = async () => {
    try {
      await client.current.publish([localAudioTrack.current, localVideoTrack.current]);
    } catch (e) {
      throw getAgoraErrorMessage(AgoraErrCode.PublishVideo, e);
    }
  };

  /**
   * マイクのmute/unmute
   */
  const muteAudio = async () => {
    try {
      await localAudioTrack.current.setMuted(!isMuteAudio);
    } catch (e) {
      throw getAgoraErrorMessage(AgoraErrCode.Mute, e);
    }
    setIsMuteAudio(!isMuteAudio);
  };

  /**
   * カメラのmute/unmute
   */
  const muteVideo = async () => {
    try {
      await localVideoTrack.current.setMuted(!isMuteVideo);
    } catch (e) {
      throw getAgoraErrorMessage(AgoraErrCode.Mute, e);
    }
    dispatchMembers({
      type: 'member_muted',
      uid: agoraUser.current.uid,
      muted: !isMuteVideo,
    });
    setIsMuteVideo(!isMuteVideo);
  };

  /**
   * ボリューム
   * @return {number} 0-1 の間
   */
  const getVolumeLevel = (uid) => {
    let audioTrack;
    if (uid === client.current.uid) {
      audioTrack = localAudioTrack.current;
    } else {
      audioTrack = client.current.remoteUsers.find(u => u.uid === uid)?.audioTrack;
    }

    if (!audioTrack) { return 0; }

    return audioTrack.getVolumeLevel();
  };

  /**
   * 画面共有/共有解除
   */
  const shareScreen = async () => {
    let newTrack;
    if (isShareScreen) {
      newTrack = await createVideoTrack();
    } else {
      try {
        newTrack = await createShareScreenTrack();
      } catch (e) {
        if (e?.name === 'AgoraRTCException' && e?.code === 'PERMISSION_DENIED') {
          // ブラウザの画面共有がキャンセルされたときは何もしない
          return;
        } else {
          throw e;
        }
      }
    }

    await newTrack.setMuted(localVideoTrack.current.muted);

    await client.current.unpublish([localVideoTrack.current]);
    localVideoTrack.current.close();

    localVideoTrack.current = newTrack;
    const me = members.find(u => u.uid === client.current.uid);
    if (me) {
      me.mained ? playOnMain() : playOnSub();
    }
    await client.current.publish([newTrack]);
    setIsShareScreen(!isShareScreen);
  };

  const leave = async () => {
    try {
      await client.current?.leave();
      localAudioTrack.current?.close();
      localVideoTrack.current?.close();
    } catch (e) {
      throw getAgoraErrorMessage(AgoraErrCode.LeaveVideo);
    }
  };

  useEffect(() => {
    return () => leave();
  }, []);

  return {
    getDevices,
    activeAudioId,
    activeCameraId,
    activeSpeekerId,
    setAudioDevice,
    setCameraDevice,
    setSpeekerDevice,
    join,
    playOnMain,
    playOnSub,
    publish,
    muteAudio,
    isMuteAudio,
    muteVideo,
    isMuteVideo,
    getVolumeLevel,
    shareScreen,
    isShareScreen,
    leave,
  };
};

useAgoraVideo.propTypes = {
  agoraUser: PropTypes.object.isRequired,
  mainTrackElementId: PropTypes.string.isRequired,
  chatRenewToken: PropTypes.func.isRequired,
};
